import { Box, Card, CardHeader, Grid, Skeleton } from "@mui/material";
import TabPill from "components/common/Tabs/TabPill";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import pending from "assets/svg/bold/pending.svg";
import PastVisitModalComponent from "./PastVisitModalComponent";
import { makeStyles } from "@mui/styles";
import { getEncounterByPatient } from "redux/features/Encounter/EncounterSlice";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  pastVisitModalroot: {
    "& .MuiTabs-vertical .MuiTabs-flexContainerVertical": {
      alignItems: "end",
      "& .MuiButtonBase-root.MuiTab-root": {
        minHeight: "40px",
        marginTop: "6px",
        justifyContent: "flex-start",
        paddingLeft: "28px",
      },
    },
  }
}));

const PastVisitModal = ({ setOtherEncounter, calledFromAppts = false, setOpenEndVisit }) => {
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const [mergedData, setMergedData] = useState([]);
  const { encounterByPatientData, isEncounterByPatientLoading } = useSelector(state => state.encounter)
  const classes = useStyles();

  useEffect(() => {
    dispatch(getEncounterByPatient(patientId));
  }, [patientId]);

  useEffect(() => {
    if (encounterByPatientData?.length) {
      let data = encounterByPatientData
        ?.map(({ datetime, ...rest }) => ({
          title: "Visit - " + moment(datetime).format("YYYY-MM-DD"),
          showPendingIcon: !calledFromAppts && rest?.sign_close_ind === "r" ? true : false,
          tab: <PastVisitModalComponent setOtherEncounter={setOtherEncounter} calledFromAppts={calledFromAppts} setOpenEndVisit={setOpenEndVisit} data={rest} />,
        }));
      setMergedData(data);
    }
  }, [encounterByPatientData]);

  if (isEncounterByPatientLoading) {
    return <DualAxisSkeletonLoader />
  }

  return (
    <Box className={classes.pastVisitModalroot}>
      <TabPill
        icon={<img alt={pending} style={{ marginLeft: "25px" }} src={pending} width={15} />}
        iconPosition="end"
        noContentDataTestId="empty-past-visit-found"
        childrenArray={mergedData}
        defaultValue={0}
        backgroundColor="#ffffff"
        indicatorBackgroundColor={false ? "#1344F1" : "#FC5A5A"}
        tabSelectedColor={false ? "#1344F1" : "#FC5A5A"}
        paddingBtn="0px"
      />
    </Box>
  );
};

export default PastVisitModal;

const DualAxisSkeletonLoader = () => {
  return (
    <Box
      data-testid={'past-visit-loader'}
      sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}
    >
      <Box sx={{ width: "100%" }} marginX={1} marginY={1}>
        <Grid container spacing={1}>
          <Grid item lg={4} md={4} xs={4} sm={4}>
            <Card
              elevation={0}
              sx={{
                width: " 100%",
                border: "1px solid #e2e2e4",
                background: "#fafafb",
              }}
            >
              <CardHeader
                subheader={
                  <Fragment >
                    {
                      [1, 2, 3, 4, 5].map((v, memberIndex) => (
                        <Skeleton
                          key={memberIndex}
                          sx={{ marginBottom: "4px" }}
                          animation="wave"
                          height={50}
                          width="100%"
                        />
                      ))
                    }
                  </Fragment>
                }
              />
            </Card>
          </Grid>
          <Grid item md={8} xs={8} sm={8} lg={8}>
            {[1, 2, 3].map((v, memberIndex) => (
              <Card
                key={memberIndex}
                elevation={0}
                sx={{
                  width: "100%",
                  border: "1px solid #e2e2e4",
                  background: "#fafafb",
                  marginBottom: "6px",
                }}
              >
                <CardHeader
                  subheader={
                    <>
                      <Skeleton
                        sx={{ marginBottom: "6px" }}
                        animation="wave"
                        height={15}
                        width="75%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "6px" }}
                        animation="wave"
                        height={15}
                        width="50%"
                      />
                      <Skeleton
                        sx={{ marginBottom: "6px" }}
                        animation="wave"
                        height={15}
                        width="25%"
                      />
                    </>
                  }
                />
              </Card>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};