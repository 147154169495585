import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AISparkImage from "assets/svg/colored/ai_sparkle_black.svg";
import search from "assets/svg/light/search.svg";
import SelectField from "components/common/FormComponents/SelectField.js";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRecordingData } from "redux/features/AmbientAI/RecordingSlice";
import { flexRowCenter } from "styles";
import DraggableChip from "./DraggableChip.js";
import CustomTooltip from "components/common/CustomTooltip/CustomTooltip.js";
import { clearMedicinesListData, deletePrescriptionsByPatientId, getMedicinesList } from "redux/features/Medications/MedicinesSlice.js";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer.js";
import { deleteLabTest } from "redux/features/Labs/labsSlice.js";
import PlanMedicationModal from "./PlanMedicationModal.js";
import { clearOrderMedicationsData } from "redux/features/Medications/OrderMedicationsSlice.js";
import CustomModal from "components/common/Modal/CustomModal/index.js";
import OrderLabModal from "./OrderLabModal.js";
import { toCapitalize } from "utils/toCapitalize.js";
import Editor from "components/Editor/Editor.js";
import { convertJsonToMarkDown } from "utils/convertJsonToMarkDown.js";
import { getEncounterById } from "redux/features/Encounter/EncounterSlice.js";

const useStyles = makeStyles((theme) => ({
  dashedBorderChip: {
    border: "1px dashed #7119FF !important",
  },
  loader: {
    border: "8px solid #f3f3f3" /* Light grey */,
    borderTop: "8px solid #7119FF" /* pink */,
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const EndVisit = forwardRef(({
  otherEncounter,
  getLabs,
  clearLabsData,
  isPostCalled,
  planMedicineData,
  setPlanMedicineData,
  encounter,
}, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const medicationsAbortControllerRef = useRef(null);
  const [planNoteData, setPlanNoteData] = useState('');
  const [showLabsSearchBar, setShowLabsSearchBar] = useState(false);
  const [showPrescriptionSearchBar, setShowPrescriptionSearchBar] = useState(false);
  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const [showLabModal, setShowLabModal] = useState(false);
  const [toBeEditedLab, setToBeEditedLab] = useState({});
  const [selectedPrescription, setSelectedPrescription] = useState("");

  // To remove the glitch of showing `no data` if it takes time to generate plan note.
  const [isPlanNoteGenerating, setIsPlanNoteGenerating] = useState(encounter?.plan_note ? false : true);

  const { getSpecificPatientData = {} } = useSelector((state) => state.specificPatient || {});
  const { medicinesData, medicinesIsLoading } = useSelector((state) => state.medicines);
  const { orderMedicationsList } = useSelector((state) => state.orderMedications);
  const { shouldFetchRecording, recPosted, processingStatus, recordingIsLoading } = useSelector((state) => state.recording);
  const { labsData, labsTestData, labsIsLoading } = useSelector((state) => state.labs);

  function setDataDetails(encounterData) {
    setIsPlanNoteGenerating(true)
    if (encounterData?.plan_note) {
      setPlanNoteData(encounterData?.plan_note || "")
      setIsPlanNoteGenerating(false)
    } else {
      const data = encounterData?.smart_note_data || ""
      setPlanNoteData(
        convertJsonToMarkDown(
          // it check if the JSON that is coming from back is stringify or not.
          typeof data !== "string"
            ? data
            : JSON.parse(removeEscapeCharacters(data || "{}"))
        )
      )
      setIsPlanNoteGenerating(false)
    }
  }

  function removeEscapeCharacters(str) {
    /**
     * This function is used to remove escape characters from the string
     * @first replace all the \n with empty string
     * @second replace all the data enclosed in ""$"" to "$"
     * @third replace all the "" with ""
     */
    let modifiedStr = str?.replace(/\\n/g, '').replace(/""(.*?)""/g, '"$1"').replace(/""/g, '""')
    return modifiedStr;
  }

  useEffect(() => {
    if (otherEncounter?.id) {
      setDataDetails(otherEncounter)
    } else {
      if (encounter?.id) {
        setDataDetails(encounter)
      }
    }
  }, [encounter, otherEncounter])

  // Poll encounter data every 5 seconds until smart_note_data is available
  useEffect(() => {
    if (!encounter?.smart_note_data) {
      const timeout = setTimeout(() => {
        dispatch(getEncounterById(encounter.id));
      }, 15000);

      return () => clearTimeout(timeout);
    }
  }, [encounter]);

  useEffect(() => {
    if (shouldFetchRecording) {
      dispatch(getRecordingData(encounter?.id))
    }
  }, [shouldFetchRecording])

  useEffect(() => {
    if (isPostCalled === 'called' && recPosted === 'posted' && encounter?.id) {
      dispatch(getRecordingData(encounter?.id))
    }
  }, [isPostCalled, encounter, recPosted])

  useEffect(() => {
    return () => {
      abortMedicationsCurrentRequest()
    }
  }, [])

  function abortMedicationsCurrentRequest() {
    if (medicationsAbortControllerRef.current) {
      medicationsAbortControllerRef.current.abort();
    }
  }

  const getMedicines = debounce((value) => {
    if (value?.length > 0) {
      // Cancel previous request if it exists
      abortMedicationsCurrentRequest();

      // Create a new AbortController
      const medicationsAbortController = new AbortController();
      medicationsAbortControllerRef.current = medicationsAbortController;
      dispatch(getMedicinesList(value, patientId, medicationsAbortController.signal));
    }
  }, DEBOUNCE_DELAY);

  const onLabsSelect = (_, v) => {
    if (v?.label || v?.value) {
      setShowLabModal(true);
      setToBeEditedLab(v);
    }
  };

  function onLabClickModal(val) {
    setToBeEditedLab(val);
    setShowLabModal(true);
  }

  const excludeLabs = (val) => {
    dispatch(deleteLabTest(patientId, val));
  };

  if (!planNoteData || recordingIsLoading || processingStatus === "processing") {
    return <Box position={"relative"}>
      <Grid container spacing={2}><div
        style={{
          minHeight: "40vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative" }}>
          <div className={classes.loader}></div>
          <img
            alt="AI Spark"
            src={AISparkImage}
            style={{
              position: "absolute",
              top: "31%",
              left: "29%",
              height: "34px",
              width: "34px",
            }}
          />
        </div>
        <div>
          <Text variant="bodyL" fontWeight={700}>
            Nephrolytics is generating your smart assessment.{" "}
          </Text>
        </div>
        <div>
          <Text variant="bodyM">This should take about a minute. </Text>
        </div>
      </div>
      </Grid>
    </Box>
  }

  const onPrescriptionSelect = (_, v) => {
    if (v?.label || v?.id) {
      setSelectedPrescription(v);
      setShowMedicationModal(true)
    }
  }

  const excludeMedicine = (prescId) => {
    let filteredPlanMedicineData = planMedicineData.filter(
      (s) => s?.id !== prescId
    );
    setPlanMedicineData(filteredPlanMedicineData);
    dispatch(deletePrescriptionsByPatientId(patientId, prescId))
  };

  return (
    <Box position={"relative"}>
      <CustomModal
        fullWidth
        maxWidth="md"
        title="Order Labs"
        open={showLabModal}
        setOpen={setShowLabModal}
      >
        <OrderLabModal
          getLabs={getLabs}
          setOpen={setShowLabModal}
          toBeEditedLab={toBeEditedLab}
          setToBeEditedLab={setToBeEditedLab}
          labsIsLoading={labsIsLoading}
          options={labsData}
          clearLabsData={clearLabsData}
        />
      </CustomModal>
      <CustomModal
        setOpen={() => {
          setSelectedPrescription(null);
          setShowMedicationModal(false);
          dispatch(clearOrderMedicationsData());
        }}
        open={showMedicationModal}
        title="Add Prescription"
        fullWidth
        maxWidth="md"
      >
        <PlanMedicationModal
          abortMedicationsCurrentRequest={abortMedicationsCurrentRequest}
          setSelectedPrescription={setSelectedPrescription}
          selectedPrescription={selectedPrescription}
          setShowMedicationModal={setShowMedicationModal}
          medicinesData={medicinesData}
          medicinesIsLoading={medicinesIsLoading}
          getMedicines={getMedicines}
        />
      </CustomModal>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box display={"flex"} alignItems={"center"} flexWrap="wrap">
            <Text id={"scd-endvisit-pt-name-val"} variant="h1">
              {" "}
              {getSpecificPatientData?.first_name
                ? getSpecificPatientData?.first_name
                : ""}
            </Text>
            <Box sx={{ fontSize: "22px", marginLeft: "2px" }}>
              {" " +
                (getSpecificPatientData?.last_name
                  ? getSpecificPatientData?.last_name
                  : "")}
            </Box>
          </Box>
          <Box>
            <Grid container>
              <Grid item sx={flexRowCenter}>
                <Text id={"scd-endvisit-pt-id"} variant="bodyM">
                  ID:&nbsp;
                </Text>
                <Text id={"scd-endvisit-pt-id-val"} variant="bodyM">
                  {" " + getSpecificPatientData?.emr_id ? getSpecificPatientData?.emr_id : ""}
                </Text>
              </Grid>
              <hr
                style={{
                  height: "16px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  border: "1px solid #E1E1E1",
                }}
              />
              <Grid item sx={flexRowCenter}>
                {getSpecificPatientData?.gender
                  ? toCapitalize(getSpecificPatientData?.gender)
                  : ""}
              </Grid>
              <hr
                style={{
                  height: "16px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  border: "1px solid #E1E1E1",
                }}
              />
              <Grid item sx={flexRowCenter}>
                <Text id={"scd-endvisit-pt-dob-val"} variant="bodyM">
                  {moment(getSpecificPatientData?.birthdate).format(
                    "YYYY-MM-DD"
                  ) ?? "- - -"}
                </Text>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Text
              id={"scd-endvisit-encounter-date"}
              variant="propertyLabel"
            >
              {" "}
              Encounter Date&nbsp;{" "}
            </Text>
            <Box>
              <Text
                id={"scd-endvisit-encounter-date-val"}
                variant="bodyM"
              >
                {encounter?.datetime
                  ? moment(encounter?.datetime).format(
                    "YYYY-MM-DD"
                  )
                  : ""}
              </Text>
            </Box>
          </Box>
          <hr
            style={{
              width: "100%",
              border: "1px solid #E1E1E1",
              marginBottom: "10px",
              marginTop: "10px",
              paddingRight: "30px",
            }}
          />
          <Box sx={{ marginTop: "10px" }}>
            <Text variant="propertyLabel">Labs</Text>
            <Stack direction="row" rowGap={1} flexWrap="wrap" gap={1}>
              {labsTestData?.map((item, index) => {
                return (
                  <CustomTooltip
                    tooltipText={
                      <Box sx={{ padding: "5px" }}>
                        <Box sx={{ marginTop: "5px" }}>
                          <Text variant="formLabel">{item?.name}</Text>
                        </Box>
                      </Box>
                    }
                    arrow={true}
                  >
                    <DraggableChip
                      onClick={() => onLabClickModal(item)}
                      className={classes.dashedBorderChip}
                      label={item?.name}
                      onDelete={() => excludeLabs(item?.id)}
                    />
                  </CustomTooltip>
                );
              })}
            </Stack>
            <Box
              sx={{
                cursor: "pointer",
                marginTop: "2px",
                fontSize: "11px",
                fontWeight: "600",
                color: "#246EFD",
              }}
              onClick={() => setShowLabsSearchBar(true)}
            >
              + Add
            </Box>
          </Box>
          {showLabsSearchBar ? (
            <Box sx={{ ...flexRowCenter, marginTop: "5px" }}>
              <SelectField
                cols={9}
                name="labs_names"
                placeholder="Add Labs"
                onSearch={getLabs}
                onChange={onLabsSelect}
                loading={labsIsLoading}
                options={labsData}
                onClear={() =>
                  labsData?.length
                    ? (clearLabsData())
                    : null
                }
                extendedSearchOptions={["description"]}
                showMoreInLabel={{ show: true, value: "instruction" }}
                border="1px solid #e0e0de"
                borderRadius="10px"
                size={"small"}
                height={"34px"}
              />
              <CloseIcon
                onClick={() => setShowLabsSearchBar(false)}
                sx={{ cursor: "pointer", ml: "5px", fontSize: "20px" }}
              />
            </Box>
          ) : null}
          <Box sx={{ marginTop: "10px" }}>
            <Text variant="propertyLabel">Prescriptions</Text>
            <Stack direction="row" rowGap={1} flexWrap="wrap" gap={1}>
              {orderMedicationsList?.map((item, index) => {
                return (
                  <CustomTooltip
                    tooltipText={
                      <Box sx={{ padding: "5px" }}>
                        <Box sx={{ marginTop: "5px" }}>
                          <Text variant="formLabel">{item?.drug_name}</Text>
                        </Box>
                      </Box>
                    }
                    arrow={true}
                  >
                    <DraggableChip
                      onClick={() => onPrescriptionSelect("", item)}
                      className={classes.dashedBorderChip}
                      label={item?.drug_name}
                      onDelete={() => excludeMedicine(item?.id)}
                    />
                  </CustomTooltip>
                );
              })}
            </Stack>
            <Box
              sx={{
                cursor: "pointer",
                marginTop: "2px",
                fontSize: "11px",
                fontWeight: "600",
                color: "#246EFD",
              }}
              onClick={() => setShowPrescriptionSearchBar(true)}
            >
              + Add
            </Box>
          </Box>
          {showPrescriptionSearchBar ? (
            <Box sx={{ ...flexRowCenter, marginTop: "5px" }}>
              <SelectField
                cols={9}
                name="icdCodes"
                placeholder="Add Prescription"
                onSearch={getMedicines}
                onChange={onPrescriptionSelect}
                loading={medicinesIsLoading}
                extendedSearchOptions={["description"]}
                showMoreInLabel={{ show: true, value: "description" }}
                border="1px solid #e0e0de"
                borderRadius="10px"
                size={"small"}
                onClear={() =>
                  medicinesData?.length
                    ? (dispatch(clearMedicinesListData()))
                    : null
                }
                options={!medicinesIsLoading ? medicinesData : []}
                height={"34px"}
                iconDetails={{ allowIcon: true, iconName: search }}
                positionLeft={true}
              />
              <CloseIcon
                onClick={() => setShowPrescriptionSearchBar(false)}
                sx={{ cursor: "pointer", ml: "5px", fontSize: "20px" }}
              />
            </Box>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          sx={{ borderLeft: "1px solid #E1E1E1" }}
        >
          <Text variant="h3" data-testid="scd-health-summary-heading">
            Notes
          </Text>
          <Box
            data-testid="scd-modal-richtexteditor"
            sx={{ marginTop: "15px", marginBottom: "15px" }}
          >
            {
              !isPlanNoteGenerating &&
              <Editor data={planNoteData} ref={ref} />
            }
          </Box>
        </Grid>
      </Grid>
      {encounter?.id &&
        encounter?.sign_close_ind === "c" && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(253 250 250 / 40%)",
              zIndex: 10999,
              pointerEvents: "auto",
              cursor: "not-allowed",
            }}
          ></div>
        )}
    </Box>
  );
});

export default EndVisit;
