import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/common/Typography/Text";
import { useNotesHistoryModalStyles } from "styles/Modals/ViewNotesHistoryModalStyle";
import CustomModal from "components/common/Modal/CustomModal";
import AddMedicatonModal from "./AddMedicatonModal";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  clearAddUpdateDeleteMedicationsData,
  getPatientMedications,
} from "redux/features/Medications/MedicationsSlice";
import { useParams } from "react-router-dom";
import Accordian from "./Accordian/MedicationsAccordian";

const MedicationDetail = ({
  isModalOpen,
  setIsOpenModal,
  activeMedications,
  inActiveMedications,
  clearMedicationsStates,
  removeMedicationFromtheState,
}) => {
  const dispatch = useDispatch();
  const classes = useNotesHistoryModalStyles();
  const { patientId } = useParams();
  const {
    deletedMedicationSuccessfully,
    updatedMedicationSuccessfully
  } = useSelector((state) => state.medications);

  const createDataDescription = (data, index) => {
    return (
      <Grid key={index} container rowSpacing={3} sx={{ marginBottom: "5px" }}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box>
            <Text variant="propertyLabel">Refills</Text>
          </Box>
          <Box>
            <Text variant="bodyXs" fontWeight={400}>
              {data?.refills_allowed === null ? '---' : data?.refills_allowed}
            </Text>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box>
            <Text variant="propertyLabel">Dispense</Text>
          </Box>
          <Box>
            <Text
              variant="bodyXs"
              data-testid="scd-medication-detail-dispense-id"
            >
              {data?.dispense_quantity ?? '---'}
            </Text>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box>
            <Text variant="propertyLabel">Instructions</Text>
          </Box>
          <Box>
            <Text variant="bodyXs">{data?.instructions ? data?.instructions : '---'}</Text>
          </Box>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (deletedMedicationSuccessfully) {
      toast.success("Medication deleted");
      dispatch(clearAddUpdateDeleteMedicationsData())
    }
  }, [deletedMedicationSuccessfully]);

  useEffect(() => {
    if (updatedMedicationSuccessfully) {
      clearMedicationsStates && clearMedicationsStates();
      dispatch(getPatientMedications(patientId));
      dispatch(clearAddUpdateDeleteMedicationsData());
    }
  }, [updatedMedicationSuccessfully])

  return (
    <Box>
      {activeMedications?.length > 0 ? (
        activeMedications?.map((ele, i) => (
          <Accordian
            key={i}
            data={createDataDescription(ele, i)}
            isActive={true}
            classes={classes}
            title={ele.drug_name}
            id={ele?.id}
            removeMedicationFromtheState={removeMedicationFromtheState}
            currentMed={ele}
          />
        ))
      ) : (
        <Box>No Data Found</Box>
      )}
      <Box marginTop={2}>
        <Text variant="bodyM" marginY="20px" fontWeight="600">
          <span data-testid="inactive-medication-detail-heading">Inactive</span>
        </Text>
        {inActiveMedications?.length > 0 ? (
          inActiveMedications?.map((ele, i) => (
            <Accordian
              key={i}
              data={createDataDescription(ele, i)}
              classes={classes}
              title={ele?.drug_name}
              id={ele?.id}
              isActive={false}
              removeMedicationFromtheState={removeMedicationFromtheState}
            />
          ))
        ) : (
          <div>No Data Found</div>
        )}
      </Box>
      <CustomModal
        fullWidth
        maxWidth="sm"
        setOpen={setIsOpenModal}
        open={isModalOpen}
        title="Add Medication"
      >
        <AddMedicatonModal
          setIsOpenModal={setIsOpenModal}
          clearMedicationsStates={clearMedicationsStates}
        />
      </CustomModal>
    </Box>
  );
};

export default MedicationDetail;
