import { Box, CircularProgress, useTheme } from "@mui/material";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { memo, useEffect, useState } from "react";
import { flexColumnCenterCenter } from "styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getPatientMedications,
} from "redux/features/Medications/MedicationsSlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import MedicationDetail from "./MedicationDetail";
import Button from "components/common/Button/Button";

const Medications = ({
  activeMedications,
  inActiveMedications,
  clearMedicationsStates,
  removeMedicationFromtheState,
}) => {
  const theme = useTheme();
  let dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [isModalOpen, setIsOpenModal] = useState(false);

  const {
    count,
    nextURL,
    medicationsIsLoading,
  } = useSelector((state) => state.medications);

  function handleVerticalScroll(event) {
    // here 5 is the threshold
    if (Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 1) {
      if (!isFetching) {
        if (nextURL !== null) {
          setTimeout(() => {
            dispatch(getPatientMedications("", nextURL));
            setIsFetching(true)
          }, 100);
        }
      }
    }
  }

  useEffect(() => {
    if (!medicationsIsLoading) {
      setIsFetching(false)
    }
  }, [medicationsIsLoading])

  return (
    <Box onScroll={(e) => handleVerticalScroll(e)} sx={{ padding: "15px !important", maxHeight: "96vh", overflowY: "auto" }}>
      <Box sx={{ marginTop: "5px" }}>
        <Text id="scd-medications-data" variant="bodyM" fontWeight="600">
          Active
        </Text>
      </Box>
      <Box
        data-testid={`scd-medication-main-box-id`}
      >
        <SkeletonWrapper
          id={"skeleton-medication-strip"}
          condition={
            (medicationsIsLoading &&
              ![...(inActiveMedications || []),
              ...(activeMedications || [])]?.length)
              ? false
              : true
          }
          multipleCount={3}
          props={[SkeletonPropsStyleGenerator("43vmax", "60px", "150px")]}
        >
          <MedicationDetail
            isModalOpen={isModalOpen}
            setIsOpenModal={setIsOpenModal}
            activeMedications={activeMedications}
            inActiveMedications={inActiveMedications}
            clearMedicationsStates={clearMedicationsStates}
            removeMedicationFromtheState={removeMedicationFromtheState}
          />
        </SkeletonWrapper>
        {
          (medicationsIsLoading && [...(inActiveMedications || []), ...(activeMedications || [])].length > 0) ?
            <Box sx={{ marginTop: "5px", ...flexColumnCenterCenter }}>
              <CircularProgress />
            </Box> : null
        }
      </Box>
      <Button
        id="scd-add-medication-button"
        text="+ Add"
        backgroundColor={"transparent"}
        btnColor={theme.palette.common.blue}
        fontWeight={"600"}
        borderRadius="10px"
        boxShadow={"none"}
        onClick={() => setIsOpenModal(true)}
        sx={{ position: "absolute", top: -49, right: 12 }}
      />
    </Box>
  );
};

export default memo(Medications);
