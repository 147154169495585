import { Box, Grid } from "@mui/material";
import CardsContainer from "Layouts/CardsContainer";
import ChatBotComponent from "components/common/ChatBotComponent/ChatBotComponent";
import SendMessageModal from "components/common/Modal/SendMessageModals";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountID } from "redux/features/Auth/loginSlice";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import { getGroupsOptions } from "redux/features/common/optionSlice";
import { alignItemsCenter, flexColumn } from "styles";
import UpperHeader from "./components/UpperHeader";
import ChatBotDrawer from "components/common/ChatBotComponent/ChatBotDrawer";
import { clearFeedbackStatuses } from "redux/features/Feedback/feedbackSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const { isChatOpen } = useSelector((state) => state.globalStates);
  const [isVerticalView, setIsVerticalView] = useState(false);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(
    localStorage.getItem("scheduledPatientDate") ??
    moment().format("YYYY-MM-DD")
  );
  const [patientsData, setPatientsData] = useState([]);
  const [filteredPatientsData, setFilteredPatientsData] = useState(patientsData);
  const [page, setPage] = useState(1);
  const { account_id } = useSelector((state) => state.auth);
  const { isNext, isFeedbackLoading } = useSelector((state) => state.Feedback);

  useEffect(() => {
    dispatch(getAccountID());
    dispatch(getGroupsOptions());
  }, [dispatch]);

  useEffect(() => {
    account_id && dispatch(getUsersList(account_id));
  }, [dispatch, account_id]);

  let mainDivStyle = {
    marginTop: "0px",
    ...flexColumn,
    ...alignItemsCenter,
    minHeight: "auto",
    borderRadius: "24px",
    background: "#FFFFFFBF",
    padding: "20px",
  };
  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    if (containerRef.current) {
      const height = window.innerHeight - containerRef.current.offsetTop;
      containerRef.current.style.height = `${height}px`;
    }
  }, [isVerticalView]);

  useEffect(() => {
    date && localStorage.setItem("scheduledPatientDate", date);
  }, [date]);

  useEffect(() => {
    !isFeedbackLoading && dispatch(clearFeedbackStatuses())
    return () => dispatch(clearFeedbackStatuses())
  }, [isFeedbackLoading, dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={12}
        xl={isChatOpen ? 9 : 12}
        xxl={isChatOpen ? 9 : 12}
        md={12}
        sm={12}
        xs={12}
      >
        <UpperHeader
          isVerticalView={isVerticalView}
          setIsVerticalView={setIsVerticalView}
          date={date}
          setDate={setDate}
          setOpen={setOpen}
        />
        <Box sx={mainDivStyle}>
          <CardsContainer
            date={date}
            page={page}
            setPage={setPage}
            isChatOpen={isChatOpen}
            patientsData={patientsData}
            isVerticalView={isVerticalView}
            setPatientsData={setPatientsData}
            setIsVerticalView={setIsVerticalView}
            filteredPatientsData={filteredPatientsData}
            setFilteredPatientsData={setFilteredPatientsData}
          />
        </Box>
        <SendMessageModal open={open} setOpen={setOpen} />
      </Grid>
      <>
        {isChatOpen === true ? (
          <>
            {viewWidth < 1200 ? (
              <ChatBotDrawer
                pathName={window.location.pathname}
                isChatOpen={true}
              />
            ) : (
              <ChatBotComponent
                pathName={window.location.pathname}
                isChatOpen={true}
              />
            )}
          </>
        ) : (
          ""
        )}
      </>
    </Grid>
  );
};

export default Dashboard;
