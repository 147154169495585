import { createSlice } from "@reduxjs/toolkit";

export const initialFeedbackState = {
    isFeedbackLoading: false,
    feedbackList: [],
    feedbackSuccess: false,
    feedbackError: null,
    count: 0,
    isNext: false,
};

const name = "Feedback";

export const feedbackSlice = createSlice({
    name,
    initialState: initialFeedbackState,
    reducers: {
        getFeedback: (state) => {
            state.isFeedbackLoading = true;
        },
        postFeedback: (state) => {
            state.isFeedbackLoading = true;
        },
        getFeedbackSuccess: (state, action) => {
            state.isFeedbackLoading = false;
            state.count = action.payload.count;
            state.isNext = action.payload.next === null ? false : true;
            state.feedbackList = action.payload.results;
            state.feedbackSuccess = true;
        },
        postFeedbackSuccess: (state, action) => {
            state.isFeedbackLoading = false;
            state.feedbackList.push(action.payload);
            state.feedbackSuccess = true;
        },
        getFeedbackFail: (state, action) => {
            state.isFeedbackLoading = false;
            state.feedbackError = action.payload;
        },
        postFeedbackFail: (state, action) => {
            state.isFeedbackLoading = false;
            state.feedbackError = action.payload;
        },
        clearFeedbackStatuses: (state) => {
            state.isFeedbackLoading = false;
            state.feedbackSuccess = false;
            state.feedbackError = null;
            state.feedbackList = [];
            state.isNext = false;
            state.count = null;
        },
    },
});

export const { clearFeedbackStatuses } = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const fetchFeedbackAPI = (page = 1) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/feedback/?page=${page}`,
    },
    type: "getFeedback",
});

export const submitFeedbackAPI = (payload) => ({
    payload: {
        apiName: name,
        method: "post",
        data: payload,
        url: `/api/feedback/`,
    },
    type: "postFeedback",
});