import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import * as toast from "hooks/notify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearLoginMessage,
  getCSRF,
  onLogin,
} from "redux/features/Auth/loginSlice";
import { onPressEnter } from "utils/onPressEnter";
import { loginValidationSchema } from "validations/auth/login/login.schema";
import LoginWrapper from "./LoginWrapper";
import { getFlags } from "redux/features/Flags/FlagsSlice";

const LoginForm = () => {
  const [value, setValue] = useState("/");
  const {
    loggedIn,
    message,
    isLoadingCSRF,
    firstTimeLogin,
    isLoading,
    csrfToken,
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: () => {
      dispatch(getCSRF());
    },
  });

  useEffect(() => {
    dispatch(clearLoginMessage());
  }, []);

  useEffect(() => {
    return () => dispatch(clearLoginMessage());
  }, []);

  useEffect(() => {
    !loggedIn && message?.length && toast.error(message);
    loggedIn && message?.length && toast.success(message);
  }, [message]);

  useEffect(() => {
    if (formik.values.password && formik.values.username) {
      !isLoadingCSRF && csrfToken && dispatch(onLogin(formik.values));
    }
  }, [csrfToken]);

  useEffect(() => {
    loggedIn && dispatch(getFlags())
    if (firstTimeLogin) {
      loggedIn && navigate("/settings?tab=1");
    } else {
      loggedIn && navigate(value ?? "/appointments");
    }
  }, [loggedIn, firstTimeLogin]);

  return (
    <LoginWrapper>
      <Box sx={{ maxWidth: "100%", width: "300px", marginTop: "33px" }}>
        <Grid container spacing={1} flexDirection={"column"}>
          <InputField
            onKeyDown={(e) => onPressEnter(e, () => formik.submitForm())}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            placeholder="Your Username Here"
            label="Username"
            formik={formik}
            name="username"
          />
          <InputField
            onKeyDown={(e) => onPressEnter(e, () => formik.submitForm())}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            placeholder={"Your Password Here"}
            label="Password"
            formik={formik}
            name="password"
            type={"password"}
          />
        </Grid>
        <Button
          isLoading={isLoading}
          borderRadius={"8px"}
          text="Login"
          sx={{
            width: "100%",
            height: "38px",
            marginTop: "10px",
            "&:hover": {
              color: "#fff",
            },
            backgroundImage:
              "linear-gradient(270deg, #4C2631 1.99%, #285677 90.93%)",
          }}
          onClick={formik.handleSubmit}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Text
            variant="bodyS"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/forgotPassword")}
          >
            Forgot Password?
          </Text>
        </Box>
      </Box>
    </LoginWrapper>
  );
};

export default LoginForm;
