import { Box } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text";
import { useState } from "react";
import { useSelector } from "react-redux";
import VitalsIcon from "../../../../../assets/svg/light/timeIcon.svg";
import LabResultDetail from "./LabResultDetail";
import CustomTooltip from "components/common/CustomTooltip/CustomTooltip";
import { checkNullOrUndefinedString } from "utils/helper";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import moment from "moment";

const LabResults = ({ formattedLabsData, labCollectionDate }) => {
  const [openVitals, setOpenVitals] = useState(false);
  const { labsResultsSuccess } = useSelector((state) => state.resultsLabs);
  const { getSpecificPatientData } = useSelector((state) => state.specificPatient);

  return (
    <Box
      sx={{
        marginTop: "35px",
        height: "40vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Text
          data-testid="scd-vitals-today"
          variant="h4"
          marginLeft="8px"
        >
          Collection Date: {labCollectionDate ? moment(labCollectionDate).format("YYYY-MM-DD") : ""}
        </Text>
      </Box>
      <Box
        data-testid="scd-labs-modal-btn"
        sx={{
          width: "29px",
          height: "29px",
          cursor: "pointer",
          position: "absolute",
          right: "10px",
          top: "12px",
        }}
        onClick={() => {
          setOpenVitals(true);
        }}
      >
        <img src={VitalsIcon} alt="" />
      </Box>
      <Box sx={{ height: "100%" }}>
        <SkeletonWrapper
          condition={labsResultsSuccess}
          multipleCount={5}
          id="lab-res-details-skeleton"
          props={[SkeletonPropsStyleGenerator("95%", "40px", "150px")]}
        >
          {formattedLabsData?.length ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
                maxHeight: "36vh",
                overflowY: "auto",
                width: "100%",
                padding: "0px 10px",
              }}
            >
              {formattedLabsData?.map(({ code, val, at_risk, range }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text
                    data-testid={`scd-labs-label-${code}`}
                    variant="h3"
                    minWidth="70px"
                    sx={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      flex: 1,
                    }}
                  >
                    {code}:
                  </Text>
                  <CustomTooltip
                    tooltipText={
                      <Box sx={{ padding: "5px" }}>
                        <Box>
                          <Text variant="h3">
                            <strong>{code} Reference Range</strong>
                          </Text>
                        </Box>
                        <Box sx={{ marginTop: "5px" }}>
                          <Text variant="formLabel">
                            {checkNullOrUndefinedString(range)}
                          </Text>
                        </Box>
                      </Box>
                    }
                    arrow
                  >
                    <Text
                      data-testid={`scd-labs-value-${val}`}
                      variant="bodyS"
                      marginLeft="10px"
                      marginRight="10px"
                      color={at_risk === "at_risk" ? "redLight" : ""}
                      sx={{ cursor: "context-menu" }}
                    >
                      {/* added this check to ensure that if we got the range then display range otherwise simply display val */}
                      {/* sometime we may get the range e.g Epithelial Cell :  "value": [ "20.0", "25.0" ] */}
                      {checkNullOrUndefinedString(
                        Array.isArray(val) && val.length > 1
                          ? `${val.join('-')}`
                          : val
                      )}
                    </Text>
                  </CustomTooltip>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "250px",
                flex: 1,
              }}
            >
              No Data Found
            </Box>
          )}
        </SkeletonWrapper>
        <CustomModal
          open={openVitals}
          setOpen={setOpenVitals}
          title={"Previous Lab Results"}
          fullWidth
          maxWidth="lg"
        >
          <LabResultDetail
            patientName={
              (getSpecificPatientData?.first_name
                ? getSpecificPatientData?.first_name
                : "") +
              " " +
              (getSpecificPatientData?.last_name
                ? getSpecificPatientData?.last_name
                : "")
            }
          />
        </CustomModal>
      </Box>
    </Box >
  );
};

export default LabResults;
